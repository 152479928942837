import { useContext } from "react";
import { UserContext } from "./UserContext";

function OtherUserRankBoard() {
  const { transformIntoTiers, chosenBoardData } = useContext(UserContext);

  return (
    <div
      className="tier-select-container"
      style={{
        marginTop: "20px",
        marginBottom: "30px",
      }}
    >
      {Object.entries(transformIntoTiers(chosenBoardData)).map(
        ([tierId, tier]) => {
          return (
            <div
              className="tier-container"
              key={tierId}
              id={`${tier.name}-tier`}
            >
              <div
                className={`tier-name-container-${tier.name}`}
                key={`${tierId}1`}
              >
                <h2>{tier.name}</h2>
              </div>
              <div>
                <div
                  className="tiers"
                  style={{
                    width: 800,
                    minHeight: 80,
                    display: "flex",
                    flexWrap: "wrap",
                    backgroundColor: "var(--grey)",
                  }}
                  key={`${tierId}2`}
                >
                  {tier.items.map((item, index) => {
                    return (
                      <div
                        style={{
                          userSelect: "none",
                          padding: "0px",
                          height: "80px",
                          width: "80px",
                          color: "white",
                          backgroundColor: "#456C86",
                        }}
                        className="items"
                        key={item.id}
                      >
                        {item.content}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
}

export default OtherUserRankBoard;
