import { items } from "./items";

export const allTiers = {
  s: {
    name: "S",
    items: [],
    value: 10,
  },
  a: {
    name: "A",
    items: [],
    value: 8,
  },
  b: {
    name: "B",
    items: [],
    value: 6,
  },
  c: {
    name: "C",
    items: [],
    value: 4,
  },
  d: {
    name: "D",
    items: [],
    value: 2,
  },
  f: {
    name: "F",
    items: [],
    value: 1,
  },
  select: {
    name: "Select",
    items: items,
    value: 0,
  },
};
