import { useContext } from 'react';
import { UserContext } from './UserContext';
import './AllUsersView.css';

function AllUsersView() {
  const { allPersonalBoardsById, setChosenBoardData } = useContext(UserContext);

  const handleClickOtherUser = (personalBoard) => {
    setChosenBoardData(personalBoard);
  };

  return (
    <div className="users-container">
      <p>Check other submissions: </p>
      <div className="users-button-container">
        {allPersonalBoardsById.map((personalBoard) => (
          <button
            key={personalBoard.user}
            onClick={() => handleClickOtherUser(personalBoard)}
          >
            {personalBoard.user}
          </button>
        ))}
      </div>
    </div>
  );
}

export default AllUsersView;
