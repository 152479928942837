import { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import './UserBoardView.css';

function UserScreenView() {
  const { setCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const handleClick = () => {
    if (inputRef.current.value !== '') {
      setCurrentUser(inputRef.current.value);
      navigate('/start-view');
    } else {
      alert('The user input is empty!');
    }
  };

  return (
    <div className="user-view-container">
      <div className="user-container">
        <p>Enter a nickname:</p>
        <div className="user-input-container">
          <input type="text" ref={inputRef} required></input>
          <button onClick={handleClick}>
            <img src={require('../images/arrow.png')} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserScreenView;
