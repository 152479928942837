import React, { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./PersonalRankBoard.css";
import { UserContext } from "./UserContext";

const onDragEnd = (result, tiers, setTiers) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceTier = tiers[source.droppableId];
    const destTier = tiers[destination.droppableId];
    const sourceItems = [...sourceTier.items];
    const destItems = [...destTier.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setTiers({
      ...tiers,
      [source.droppableId]: {
        ...sourceTier,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destTier,
        items: destItems,
      },
    });
  } else {
    const tier = tiers[source.droppableId];
    const copiedItems = [...tier.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setTiers({
      ...tiers,
      [source.droppableId]: {
        ...tier,
        items: copiedItems,
      },
    });
  }
};

function PersonalRankBoard() {
  const { currentBoard, setCurrentBoard } = useContext(UserContext);
  return (
    <div className="all-tiers-container">
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, currentBoard, setCurrentBoard)}
      >
        {Object.entries(currentBoard).map(([tierId, tier]) => {
          return (
            <div
              className="tier-container"
              key={tierId}
              id={`${tier.name}-tier`}
            >
              <div className={`tier-name-container-${tier.name}`}>
                <h2>{tier.name}</h2>
              </div>
              <div>
                <Droppable
                  droppableId={tierId}
                  key={tierId}
                  direction="horizontal"
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="draggable-items"
                        style={{
                          background: snapshot.isDraggingOver
                            ? "#2a2a40"
                            : `var(--grey)`,
                        }}
                      >
                        {tier.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    className="items"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: "none",
                                      backgroundColor: snapshot.isDragging
                                        ? "#a1485b"
                                        : "#a1485b",
                                      color: "white",
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    {item.content}
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
}

export default PersonalRankBoard;
