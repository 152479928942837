import { useContext } from "react";
import { UserContext } from "./UserContext";
import Navbar from "./Navbar";
import PersonalRankBoard from "./PersonalRankBoard";
import PersonalBoardSaved from "./PersonalBoardSaved";
import "./BoardView.css";

function PersonalBoardView() {
  const { currentId, isSaved } = useContext(UserContext);

  return (
    <div className="board-view-container">
      <Navbar />
      <div className="board-view-board-info-container">
        <div className="board-view-board-code">Board Code: {currentId}</div>
        <div className="board-view-current-board-type">Personal Board</div>
      </div>
      {isSaved ? <PersonalBoardSaved /> : <PersonalRankBoard />}
    </div>
  );
}

export default PersonalBoardView;
