import Navbar from './Navbar';
import OtherUserRankBoard from './OtherUserRankBoard';
import './BoardView.css';
import { useContext } from 'react';
import { UserContext } from './UserContext';
import AllUsersView from './AllUsersView';
import './OtherUserBoardView';

function OtherUserBoardView() {
  const { currentId, chosenBoardData } = useContext(UserContext);
  return (
    <div className="board-view-container">
      <Navbar />
      <div className="board-view-board-info-container">
        <div className="board-view-board-code">Board Code: {currentId}</div>
        <div className="board-view-current-board-type">
          {chosenBoardData
            ? `Board User: ${chosenBoardData.user}`
            : 'Select a user'}
        </div>
      </div>
      <OtherUserRankBoard currentId={currentId} />
      <AllUsersView />
    </div>
  );
}

export default OtherUserBoardView;
