import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import './Navbar.css';

function Navbar() {
  const {
    currentView,
    setCurrentView,
    currentUser,
    currentBoard,
    currentId,
    setIsSaved,
    isSaved,
    setPersonalBoardSaved,
    transformIntoTiers,
    baseUrl,
  } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    setCurrentView('start');
    if (currentUser) {
      navigate('/start-view');
    } else {
      navigate('/');
    }
  };

  const handleChangeViewClick = () => {
    if (currentView === 'personal') {
      navigate('/shared-board-view');
      setCurrentView('shared');
    }
    if (currentView === 'shared') {
      setCurrentView('personal');
      navigate('/personal-board-view');
    }
  };

  const handleSaveBoard = async () => {
    try {
      const boardObj = {};
      Object.entries(currentBoard).forEach(([tier, objTier]) => {
        if (tier !== 'select') {
          objTier.items.forEach((item) => {
            boardObj[item.id] = objTier.value;
            boardObj[`${item.id}_counter`] = 1;
          });
        } else {
          objTier.items.forEach((item) => {
            boardObj[item.id] = 0;
            boardObj[`${item.id}_counter`] = 0;
          });
        }
      });

      boardObj['id_shared_board'] = currentId;

      await fetch(baseUrl + '/shared-boards?board=' + currentId, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify(boardObj),
      });

      boardObj['user'] = currentUser;

      await fetch(baseUrl + '/new-personal-board', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(boardObj),
      });

      setPersonalBoardSaved(transformIntoTiers(boardObj));
      setIsSaved(true);
      navigate('/personal-board-view');
    } catch {}
  };

  const handleChangeToCompareBoards = () => {
    setCurrentView('personal');
    navigate('/other-user-board-view');
  };

  return (
    <div className="navbar-container">
      <div className="logo-container">
        <div onClick={handleLogoClick} className="navbar-logo">
          RankRiot
        </div>
        {currentUser ? <div className="user">{currentUser}</div> : null}
      </div>

      <div className="navbar-buttons-container">
        {isSaved ? (
          <button onClick={handleChangeToCompareBoards}>Compare Boards</button>
        ) : null}
        {currentView === 'personal' && !isSaved ? (
          <button className="save-button" onClick={handleSaveBoard}>
            Save
          </button>
        ) : null}
        <button onClick={handleChangeViewClick}>Change View</button>
      </div>
    </div>
  );
}

export default Navbar;
