import Navbar from "./Navbar";
import SharedRankBoard from "./SharedRankBoard";
import "./BoardView.css";
import { useContext } from "react";
import { UserContext } from "./UserContext";

function SharedBoardView() {
  const { currentId } = useContext(UserContext);
  return (
    <div className="board-view-container">
      <Navbar />
      <div className="board-view-board-info-container">
        <div className="board-view-board-code">Board Code: {currentId}</div>
        <div className="board-view-current-board-type">Shared Board</div>
      </div>
      <SharedRankBoard currentId={currentId} />
    </div>
  );
}

export default SharedBoardView;
