import Navbar from "./Navbar";
import "./JoinBoardScreenView.css";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";

function JoinBoardScreenView() {
  const { setCurrentView, setCurrentId, baseUrl } = useContext(UserContext);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  let allCurrentlyExistingBoardIds;

  const handleClick = () => {
    if (allCurrentlyExistingBoardIds.includes(inputRef.current.value)) {
      setCurrentId(inputRef.current.value);
      setCurrentView("personal");
      inputRef.current.value = "";
      navigate("/personal-board-view");
    } else {
      alert("That board does not exist!");
      inputRef.current.value = "";
    }
  };

  (async () => {
    try {
      const data = await fetch(baseUrl + "/all-ids");
      const allIds = await data.json();

      allCurrentlyExistingBoardIds = allIds.map((idObj) => idObj["id"]);
    } catch {}
  })();

  return (
    <div className="join-board-screen-view-container">
      <Navbar />
      <div className="join-board-screen-view-input-container">
        <div className="join-board-screen-input-container">
          <h3>
            Enter a board <br /> code to join:
          </h3>
          <div className="join-container">
            <input type="text" id="board-to-join-input" ref={inputRef} />
            <div onClick={handleClick} className="button">
              <img src={require("../images/send.png")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinBoardScreenView;
