import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import StartView from './StartView';
import JoinBoardScreenView from './JoinBoardScreenView';
import PersonalBoardView from './PersonalBoardView';
import SharedBoardView from './SharedBoardView';
import UserBoardView from './UserBoardView';
import PersonalBoardSaved from './PersonalBoardSaved';
import OtherUserBoardView from './OtherUserBoardView';
import { UserContext } from './UserContext';
import { allTiers } from '../allTiers';
import { items } from '../items';

function App() {
  const [currentView, setCurrentView] = useState('user');
  const [currentId, setCurrentId] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [currentBoard, setCurrentBoard] = useState(allTiers);
  const [isSaved, setIsSaved] = useState(false);
  const [allPersonalBoardsById, setAllPersonalBoardsById] = useState('');
  const [personalBoardSaved, setPersonalBoardSaved] = useState('');
  const [chosenBoardData, setChosenBoardData] = useState('');
  const [sharedBoard, setSharedBoard] = useState([]);

  const baseUrl = 'https://rank-riot-cc29.onrender.com';
  // const baseUrl = 'http://localhost:3000'

  const setNewBoard = () => {
    setCurrentBoard(allTiers);
  };

  useEffect(() => {
    (async () => {
      try {
        const allDataById = await fetch(
          baseUrl + '/shared-boards?board=' + currentId
        );
        const allData = await allDataById.json();
        const allAverages = {};

        items.forEach((item) => {
          allAverages[item.id] = {
            average: allData[item.id] / allData[`${item.id}_counter`],
            item: item,
          };
        });

        const newTiersObj = {
          s: {
            name: 'S',
            items: [],
          },
          a: {
            name: 'A',
            items: [],
          },
          b: {
            name: 'B',
            items: [],
          },
          c: {
            name: 'C',
            items: [],
          },
          d: {
            name: 'D',
            items: [],
          },
          f: {
            name: 'F',
            items: [],
          },
        };

        for (const item in allAverages) {
          const average = allAverages[item].average;
          switch (true) {
            case average >= 9.5:
              newTiersObj['s'].items.push(allAverages[item].item);
              break;
            case average >= 7.5:
              newTiersObj['a'].items.push(allAverages[item].item);
              break;
            case average >= 5.5:
              newTiersObj['b'].items.push(allAverages[item].item);
              break;
            case average >= 3.5:
              newTiersObj['c'].items.push(allAverages[item].item);
              break;
            case average >= 1.5:
              newTiersObj['d'].items.push(allAverages[item].item);
              break;
            case average >= 1:
              newTiersObj['f'].items.push(allAverages[item].item);
              break;
            default:
          }
        }

        setSharedBoard(newTiersObj);
      } catch {
        setSharedBoard({});
      }
    })();
  }, [currentId, currentView]);

  useEffect(() => {
    (async () => {
      try {
        const boardsData = await fetch(
          baseUrl + '/all-personal-boards?id=' + currentId
        );
        const boards = await boardsData.json();

        setAllPersonalBoardsById(boards);
      } catch {
        setAllPersonalBoardsById({});
      }
    })();
  }, [currentId, currentView]);

  const transformIntoTiers = (board) => {
    const newTiersObj = {
      s: {
        name: 'S',
        items: [],
      },
      a: {
        name: 'A',
        items: [],
      },
      b: {
        name: 'B',
        items: [],
      },
      c: {
        name: 'C',
        items: [],
      },
      d: {
        name: 'D',
        items: [],
      },
      f: {
        name: 'F',
        items: [],
      },
    };

    for (const item of items) {
      const itemVal = board[item.id];
      switch (true) {
        case itemVal === 10:
          newTiersObj['s'].items.push(item);
          break;
        case itemVal === 8:
          newTiersObj['a'].items.push(item);
          break;
        case itemVal === 6:
          newTiersObj['b'].items.push(item);
          break;
        case itemVal === 4:
          newTiersObj['c'].items.push(item);
          break;
        case itemVal === 2:
          newTiersObj['d'].items.push(item);
          break;
        case itemVal === 1:
          newTiersObj['f'].items.push(item);
          break;
        default:
      }
    }
    return newTiersObj;
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await fetch(
          baseUrl + '/personal-boards?id=' + currentId + '&user=' + currentUser
        );
        const personalBoardData = await data.json();

        setPersonalBoardSaved(transformIntoTiers(personalBoardData));
        setIsSaved(true);
      } catch {
        setIsSaved(false);
      }
    })();
  }, [currentId, currentUser]);

  return (
    <>
      <div className="App">
        <header className="App-header">
          <UserContext.Provider
            value={{
              currentView,
              setCurrentView,
              currentId,
              setCurrentId,
              currentUser,
              setCurrentUser,
              currentBoard,
              setCurrentBoard,
              isSaved,
              setIsSaved,
              allPersonalBoardsById,
              chosenBoardData,
              setChosenBoardData,
              personalBoardSaved,
              setPersonalBoardSaved,
              sharedBoard,
              transformIntoTiers,
              setNewBoard,
              baseUrl,
            }}
          >
            <Routes>
              <Route path="/" element={<UserBoardView />} />
              <Route path="/start-view" element={<StartView />} />
              <Route
                path="/join-board-view"
                element={<JoinBoardScreenView />}
              />
              <Route
                path="/personal-board-view"
                element={<PersonalBoardView />}
              />
              <Route path="/shared-board-view" element={<SharedBoardView />} />
              <Route
                path="/other-user-board-view"
                element={<OtherUserBoardView />}
              />
              <Route
                path="/personal-board-saved"
                element={<PersonalBoardSaved />}
              />
            </Routes>
          </UserContext.Provider>
        </header>
      </div>
    </>
  );
}

export default App;
