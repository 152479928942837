export const items = [
  {
    id: "meron_pan",
    content: "Meron Pan",
  },
  {
    id: "tuna_onigiri",
    content: "Tuna Onigiri",
  },
  {
    id: "shu_cream",
    content: "Shu Cream",
  },
  {
    id: "asari_soup",
    content: "Asari Soup",
  },
  {
    id: "seafood_ramen",
    content: "Seafood Ramen",
  },
  {
    id: "yakisoba_pan",
    content: "Yakisoba Pan",
  },
  {
    id: "monster_green",
    content: "Monster Green",
  },
  {
    id: "haribo_cocacola",
    content: "Haribo Cola",
  },
  {
    id: "fami_chicken",
    content: "FamiChicken",
  },
  {
    id: "waffle_cone_ice_cream",
    content: "Waffle Cone",
  },
  {
    id: "nikuman",
    content: "Nikuman",
  },
  {
    id: "highball",
    content: "Highball",
  },
  {
    id: "fruit_sandwich",
    content: "Fruit Sandwich",
  },
  {
    id: "super_cup",
    content: "Super Cup",
  },
  {
    id: "pizza_nikuman",
    content: "Pizza Nikuman",
  },
  {
    id: "mugicha",
    content: "Mugicha",
  },
  {
    id: "ocha",
    content: "Ocha",
  },
  {
    id: "beer",
    content: "Beer",
  },
  {
    id: "horoyoi",
    content: "Horoyoi Chu-hai",
  },
  {
    id: "strong_zero",
    content: "Strong Zero",
  },
];