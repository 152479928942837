import "./StartView.css";
import { items } from "../items.js";
import Navbar from "./Navbar";
import { useContext } from "react";
import { UserContext } from "./UserContext";
import { useNavigate } from "react-router-dom";

function StartView() {
  const { setCurrentView, setCurrentId, setNewBoard, currentUser, baseUrl } =
    useContext(UserContext);
  const navigate = useNavigate();

  const createRoom = async () => {
    try {
      const data = await fetch(baseUrl + "/new-id");
      const newId = await data.json();

      const newBoard = { id_shared_board: newId, user_owner: currentUser };
      items.forEach((item) => {
        newBoard[item.id] = 0;
        newBoard[`${item.id}_counter`] = 0;
      });

      await fetch(baseUrl + "/new-shared-board", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(newBoard),
      });

      setCurrentId(newId);
      navigate("/personal-board-view");
    } catch {}
  };

  const handleCreateClick = () => {
    createRoom();
    setNewBoard();
    setCurrentView("personal");
  };

  const handleJoinClick = () => {
    navigate("/join-board-view");
  };

  return (
    <div className="start-view-container">
      <Navbar />
      <div className="start-view-items-container">
        <div id="start-view-options-container">
          <button onClick={handleCreateClick}>Create Board</button>
          <button onClick={handleJoinClick}>Join Board</button>
        </div>
      </div>
    </div>
  );
}

export default StartView;
